import styled, {keyframes} from "styled-components"
import theme from "../01-settings/theme"
import { rgba } from "../02-tools/rgba"

const rainbow = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
`;

const LoaderOverlay = styled.div`
  align-items: center;
  background-color: ${rgba(theme.colors.black, 0.7)};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
`

const LoaderContainer = styled.div`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.black};
  border-radius: ${props => props.nav ? "100%" : "5px"};
  display: flex;
  flex-direction: column;
  max-width: 800px;
  padding: ${theme.spacing.l};
  text-align: center;
`

const LoaderText = styled.p`
  margin: 0;
  text-align: center;
`

const LoaderBlock = styled.div`
  animation: 1s linear infinite normal both ${rainbow};
  display: block;
  background: ${props => `linear-gradient(to right, ${props.theme.colorOne}, ${props.theme.colorTwo}, ${props.theme.colorOne}, ${props.theme.colorTwo}, ${props.theme.colorOne})`};
  background-position: 0 0;
  background-size: 200%;
  height: 6px;
  margin: ${theme.margin.stack};
  width: 200px;
  z-index: 1;
`

export { LoaderOverlay, LoaderContainer, LoaderText, LoaderBlock }