import styled from "styled-components"
import theme from "../01-settings/theme"

const MainNavBlock = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const MainNavItem = styled.li`
  margin: ${theme.margin.stackXL};

  @media (min-width: 768px) {
    display: inline-block;
    margin-right: ${theme.spacing.xl};
    margin-top: ${theme.spacing.s};

    &:last-of-type {
      margin-right: 0;
    }
  }
  
  @media (min-width: 992px) {
    margin: ${theme.margin.inlineXL};

    &:last-of-type {
      margin: 0;
    }
  }
`

export { MainNavBlock, MainNavItem }