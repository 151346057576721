import customTheme from "./styles/01-settings/theme"

export const themes = {
    themeOne: {
      colorOne: customTheme.colors.purple,
      colorTwo: customTheme.colors.red,
      gradient: `linear-gradient(15deg, ${customTheme.colors.purple}, ${customTheme.colors.red}, ${customTheme.colors.purple})`,
      accentColor: customTheme.colors.purple,
      accentColorHover: customTheme.colors.purpleHover,
      accentColorActive: customTheme.colors.purpleActive,
      accentColorHoverLight: customTheme.colors.purpleHoverInverse,
      activeMarker: customTheme.colors.brightPurple
    },
    themeTwo: {
      colorOne: customTheme.colors.mutedRed,
      colorTwo: customTheme.colors.mutedPink,
      gradient: `linear-gradient(15deg, ${customTheme.colors.mutedRed}, ${customTheme.colors.mutedPink}, ${customTheme.colors.mutedRed})`,
      accentColor: customTheme.colors.mutedRed,
      accentColorHover: customTheme.colors.mutedRedHover,
      accentColorActive: customTheme.colors.mutedRedActive,
      accentColorHoverLight: customTheme.colors.mutedRedHoverInverse,
      activeMarker: customTheme.colors.brightRed
    },
    themeThree: {
      colorOne: customTheme.colors.mutedRed,
      colorTwo: customTheme.colors.mutedGold,
      gradient: `linear-gradient(15deg, ${customTheme.colors.mutedRed}, ${customTheme.colors.mutedGold}, ${customTheme.colors.mutedRed})`,
      accentColor: customTheme.colors.mutedRed,
      accentColorHover: customTheme.colors.mutedRedHover,
      accentColorActive: customTheme.colors.mutedRedActive,
      accentColorHoverLight: customTheme.colors.mutedRedHoverInverse,
      activeMarker: customTheme.colors.brightRed
    },
    themeFour: {
      colorOne: customTheme.colors.darkGreen,
      colorTwo: customTheme.colors.lightGreen,
      gradient: `linear-gradient(15deg, ${customTheme.colors.darkGreen}, ${customTheme.colors.lightGreen}, ${customTheme.colors.darkGreen})`,
      accentColor: customTheme.colors.darkGreen,
      accentColorHover: customTheme.colors.darkGreenHover,
      accentColorActive: customTheme.colors.darkGreenActive,
      accentColorHoverLight: customTheme.colors.lightGreen,
      activeMarker: customTheme.colors.brightGreen
    },
    themeFive: {
      colorOne: customTheme.colors.blue,
      colorTwo: customTheme.colors.lightBlue,
      gradient: `linear-gradient(15deg, ${customTheme.colors.blue}, ${customTheme.colors.lightBlue}, ${customTheme.colors.blue})`,
      accentColor: customTheme.colors.blue,
      accentColorHover: customTheme.colors.blueHover,
      accentColorActive: customTheme.colors.blueActive,
      accentColorHoverLight: customTheme.colors.lightBlue,
      activeMarker: customTheme.colors.brightBlue
    },
    themeSix: {
      colorOne: customTheme.colors.mutedRed,
      colorTwo: customTheme.colors.mutedPurple,
      gradient: `linear-gradient(15deg, ${customTheme.colors.mutedRed}, ${customTheme.colors.mutedPurple}, ${customTheme.colors.mutedRed})`,
      accentColor: customTheme.colors.mutedRed,
      accentColorHover: customTheme.colors.mutedRedHover,
      accentColorActive: customTheme.colors.mutedRedActive,
      accentColorHoverLight: customTheme.colors.mutedRedHoverInverse,
      activeMarker: customTheme.colors.brightRed
    }
  }