import styled from "styled-components"
import theme from "../01-settings/theme"

const ListPageBlock = styled.div`
  background: ${props => props.theme.gradient};
  min-height: 100vh;
  padding: ${theme.padding.inset};
`
const ListPageContent = styled.section`
  background-color: ${theme.colors.white};
  padding: ${theme.padding.insetL};

  @media (min-width: 992px) {
    margin: 0 auto;
    max-width: 1200px;
  }
`

const ListPageHeader = styled.header``

const ListPageHeading = styled.h1`
  text-transform: capitalize;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
const ListItem = styled.li`
  border-bottom: 1px solid;
  border-bottom-color: ${props => props.theme.accentColor};
  padding: ${theme.spacing.xl} 0;

  &:last-of-type {
    border: 0;
  }

  * {
    margin: 0;
  }
`

const ListItemHeader = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const ListItemHeading = styled.h2`
  font-size: ${theme.fontSizes.xl};
`

const ListItemCount = styled.p`
  font-weight: ${theme.fontWeights.bold};
`

const ListItemDescription = styled.div`
  margin: ${theme.margin.stackS};
`

export { ListPageBlock, ListPageContent, ListPageHeader, ListPageHeading, List, ListItem, ListItemHeader, ListItemHeading, ListItemCount, ListItemDescription }