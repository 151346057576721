import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

import { ThemeProvider } from "styled-components"
import GlobalElementStyles from "./styles/04-elements/elements"
import { themes } from "./themes"

import Home from "./components/Pages/Home"
import Story from "./components/Pages/Story"
import ListPage from "./components/Pages/ListPage"

export default function App(props) {
  let theme

  function randomizeTheme() {
    var keys = Object.keys(themes);    
    theme = (themes[keys[ keys.length * Math.random() << 0]])

    return theme
  }

  return (
    <ThemeProvider theme={randomizeTheme}>
      <GlobalElementStyles />
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path={["/", "/stories"]} component={Home} />
          <Route exact path="/:list">
            <ListPage />
          </Route>
          <Route path="/story/:slug">
            <Story />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  )
}
