import React from "react"
import { useAsync } from "react-async"
import { getAboutInfo } from "../api/storymapApi"

import styled from "styled-components"
import theme from "../styles/01-settings/theme"

import { 
  DialogOverlay,
  DialogBlock, 
  DialogContentContainer, 
  DialogHeading, 
  DialogContent,
  Copyright 
} from "../styles/05-components/dialogStyles"

import Button from "./Button"
import Icon from "./Icon"
import SocialActions from "./SocialActions"

const StyledSocialAction = styled(SocialActions)`
  margin: ${theme.margin.stackXXL};
`

const StyledButton = styled(Button)`
  align-self: flex-end;
  width: auto;
  padding: 0;
`

export default function Dialog(props) {
  const { data, error, isPending } = useAsync({ promiseFn: getAboutInfo }) 
  const year = new Date().getFullYear() 

  if(isPending || !data || error) {
    // TODO: should probably add a loading state or something
    return null;
  }

  function handleAboutInfoChange() {
    props.toggleAboutInfo()
  }

  return (
    <DialogOverlay open={props.open}>
      <DialogBlock>
        <StyledButton onClick={(e) => { handleAboutInfoChange() }}><Icon>close</Icon></StyledButton>
        <DialogContentContainer>
          <DialogHeading>{props.heading}</DialogHeading>
          <DialogContent dangerouslySetInnerHTML={{__html: data}}></DialogContent>
          <StyledSocialAction message="Columbia Story Map" />
          <Copyright>Copyright © {year}, One Columbia for Arts and History, Inc., All rights reserved.</Copyright>
        </DialogContentContainer>
      </DialogBlock>
    </DialogOverlay>
  )
}
