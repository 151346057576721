import space from "./spacing"

export default {
    insetXXS: `${space.xxs}`,
    insetXS: `${space.xs}`,
    insetS: `${space.s}`,
    inset: `${space.base}`,
    insetL: `${space.l}`,
    insetXL: `${space.xl}`,
    insetXXL: `${space.xxl}`,
    insetSquishXXS: `${space.xxs} ${space.xs}`,
    insetSquishXS: `${space.xxs} ${space.s}`,
    insetSquishS: `${space.xs} ${space.base}`,
    insetSquish: `${space.s} ${space.l}`,
    insetSquishL: `${space.base} ${space.xl}`,
    insetSquishXL: `${space.l} ${space.xxl}`,
    insetSquishXXL: `${space.xxl} ${space.xxxl}`,
}
