import React, { useState } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';

import Icon from "./Icon"

export default function Map(props) {
  // todo - replace this token with a hidden one that Lee owns
  const TOKEN = "pk.eyJ1Ijoic2xrZW5uZWR5IiwiYSI6ImNrOGRnOXhmYTB1MTczZXF1aG8ydW5zODcifQ.ww4u4e1vzRdSKjzfz1FPHA";
  const mapStyle = "mapbox://styles/slkennedy/ck8ne7xsk2ki71ipnivz1kwun";
  
  const story = props.story;

  const [viewport, setViewport] = useState({
    latitude: story.location.lat,
    longitude: story.location.lng,
    zoom: 14
  });

  return (
    <ReactMapGL
      {...viewport}
      height="200px"
      width="100%"
      mapStyle={mapStyle}
      mapboxApiAccessToken={TOKEN}
      onViewportChange={(nextViewport) => setViewport(nextViewport)}>
        <Marker
          latitude={story.location.lat}
          longitude={story.location.lng}>
            <Icon className="black">location_on</Icon>
        </Marker>
    </ReactMapGL>
  )
}
