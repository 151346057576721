import React from "react"
import styled from "styled-components"
import theme from "../01-settings/theme"

const BaseIcon = ({ className, children }) => (
  <span className={className}>{children}</span>
)

const IconBlock = styled(BaseIcon)`
  @keyframes bounce { 
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-5px);
    }

    100% {
      transform: translateY(0);
    }
  } 
  
  font-size: 26px;
  
  &.selected {
    animation-name: bounce;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    color: ${props => props.theme.activeMarker};
  }

  &.action {
    color: ${props => props.theme.accentColor};
  }

  &.white {
    color: ${theme.colors.white};
  }

  &.black {
    color: ${theme.colors.black};
  }
`

export { IconBlock }