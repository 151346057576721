import styled from "styled-components"
import theme from "../01-settings/theme"
import SRHeading from "../06-utilities/srHeading"

const TagsBlock = styled.section`
    margin: ${theme.margin.stackL};
`

const TagsHeading = styled.h1`
    font-size: ${theme.fontSizes.s};
`

const TagsSRHeading = styled(SRHeading)``

const TagsList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`

const TagsTag = styled.li`
    display: inline-block;
    margin-right: ${theme.spacing.s};
    margin-top: ${theme.spacing.s};

    &:last-of-type {
        margin-right: 0;
    }

    @media (min-width: 768px) {
        margin-top: 0;
    }
`

export { TagsBlock, TagsHeading, TagsSRHeading, TagsList, TagsTag }