import React from "react"
import { LoaderOverlay, LoaderContainer, LoaderText, LoaderBlock } from "../styles/05-components/loaderStyles"

export default function Loader(props) {
    return (
        <LoaderOverlay>
            <LoaderContainer>
                <LoaderText>Loading</LoaderText>
                <LoaderBlock></LoaderBlock>
            </LoaderContainer>
        </LoaderOverlay>
    )
}
