import queryString from 'query-string';
import { apiHelper } from "./apiHelper";

export function getStories({ props, query }) {
  const qs = queryString.stringify(query);
  const search = new queryString.parse(props.location.search);

  if(search.project || search.trail) {
    return apiHelper(`wp-json/storymap/v1/stories${qs ? `?${qs}` : ''}`);
  }
  
  return apiHelper(`wp-json/storymap/v1/stories`);
}

export function getStory({ slug }) {
  return apiHelper(`wp-json/storymap/v1/stories/${slug}`);
}

export function getListItems({ apiPath }) {
  return apiHelper(`wp-json/storymap/v1/${apiPath}`);
}

export function getAboutInfo() {
  return apiHelper(`wp-json/storymap/v1/about`);
}
