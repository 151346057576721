export default {
    xxs: "0.3776rem",
    xs: "0.6110rem",
    s: "0.9888rem",
    base: "1.6rem",
    l: "2.5888rem",
    xl: "4.1886rem",
    xxl: "6.7772rem",
    xxxl: "10.9656rem",
    xxxxl: "17.7428rem"
}
