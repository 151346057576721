import { createGlobalStyle } from "styled-components"
import theme from "../01-settings/theme"

const GlobalElementStyles = createGlobalStyle`
    body {
        font-family: ${theme.fontFamily.primaryText};
        line-height: 1.5;  
    }

    h1,
    h2,
    h3,
    h4, 
    h5, 
    h6 {
        color: ${theme.colors.black};
        font-family: ${theme.fontFamily.primaryHeading};
    }

    h1 {
        font-size: ${theme.fontSizes.xxxl};
        margin: ${theme.margin.stackXL};
    }

    h2 {
        font-size: ${theme.fontSizes.xxl};
        margin: ${theme.margin.stackL};
    }

    h3 {
        font-size: ${theme.fontSizes.xl};
        margin: ${theme.margin.stack};
    }

    h4 {
        font-size: ${theme.fontSizes.l};
        margin: ${theme.margin.stack};
    }

    h5 {
        font-size: ${theme.fontSizes.base};
        margin: ${theme.margin.stack};
    }

    h6 {
        font-size: ${theme.fontSizes.base};
        margin: ${theme.margin.stack};
    }

    p {
        font-family: ${theme.fontFamily.primaryText};
        margin: ${theme.margin.stackS};
    }

    h1:first-child,
    h2:first-child,
    h3:first-child,
    h4:first-child,
    h5:first-child,
    h6:first-child {
        margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: ${theme.margin.stackXL};
    }

    h1 + p, 
    h2 + p, 
    h3 + p, 
    h4 + p, 
    h5 + p, 
    h6 + p {
        margin: ${theme.margin.stackS};
    }

    h1 + ol, 
    h2 + ol, 
    h3 + ol, 
    h4 + ol, 
    h5 + ol, 
    h6 + ol {
        margin: ${theme.margin.stackXS};
    }

    h1 + ul, 
    h2 + ul, 
    h3 + ul, 
    h4 + ul, 
    h5 + ul, 
    h6 + ul {
        margin: ${theme.margin.stackXS};
    }

    h1 + dl,
    h2 + dl,
    h3 + dl,
    h4 + dl,
    h5 + dl,
    h6 + dl {
        margin: ${theme.margin.stackXS};
    }

    h1 + h2,
    h2 + h3,
    h3 + h4,
    h4 + h5,
    h5 + h6 {
        margin-top: 0;
    }

    a {
        color: ${props => props.theme.accentColor};

        &:hover,
        &:focus {
            color: ${props => props.theme.accentColorHover};
        }

        &:active {
            color: ${props => props.theme.accentColorActive};
        }

        &.inverse {
            color: ${theme.colors.white};

            &:hover,
            &:focus,
            &:active {
                color: ${props => props.theme.accentColorHoverLight};
            }
        }

        &.no-border {
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }

    button {
        font-family: ${theme.fontFamily.primaryText};
        font-size: ${theme.fontSizes.s};

        &:hover {
            cursor: pointer;
        }
    }

    ol,
    ul {
        margin-top: ${theme.spacing.s};

    }

    ol ol,
    ol ul,
    ul ol,
    ul ul  {
        margin-top: 0;
    }
`

export default GlobalElementStyles
