import space from "./spacing"
  
export default {
    stackXXS: `${space.xxs} 0 0 0`,
    stackXS: `${space.xs} 0 0 0`,
    stackS: `${space.s} 0 0 0`,
    stack: `${space.base} 0 0 0`,
    stackL: `${space.l} 0 0 0`,
    stackXL: `${space.xl} 0 0 0`,
    stackXXL: `${space.xxl} 0 0 0`,
    inlineXXS: `0 ${space.xxs} 0 0`,
    inlineXS: `0 ${space.xs} 0 0`,
    inlineS: `0 ${space.s} 0 0`,
    inline: `0 ${space.base} 0 0`,
    inlineL: `0 ${space.l} 0 0`,
    inlineXL: `0 ${space.xl} 0 0`,
    inlineXXL: `0 ${space.xxl} 0 0`,
}
