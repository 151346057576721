import styled from "styled-components"
import theme from "../01-settings/theme"
import { rgba } from "../02-tools/rgba"

const StorySummaryBlock = styled.div`
  background: ${props => props.theme.gradient};
  border-radius: 4px;
  bottom: 35px;
  color: ${theme.colors.white};
  left: 2.5%;
  padding: ${theme.padding.insetS};
  padding-bottom: ${theme.spacing.base};
  position: absolute;
  width: 95%;
  z-index: 1;

  display: flex;

  @media (min-width: 768px) {   
    background: transparent;
    display: none;
    width: 100%;
  }
`

const StorySummaryDismissContainer = styled.div`
  display: ${props => props.isTrail ? "none" : "block"};
  position: absolute;
  right: 0;

  button {
    appearance: none;
    background-color: transparent;
    border: 0;
    padding: ${theme.padding.insetXS};
  }
`

const StorySummaryContentContainer = styled.div`
  background-color: ${props => props.listViewEnabled ? "transparent" : theme.colors.darkGrey};
  border-radius: ${props => props.isTrail ? "0" : "4px"};
  box-shadow: 0 0 0 4px ${rgba(theme.colors.white, 0.08)};
  position: relative;

  * {
    margin: 0;
  }

  flex-grow: 1;

  @media (min-width: 768px) {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  }
`

const StorySummaryInfoContainer = styled.div`
  flex-basis: 90%;
`

const StorySummaryDate = styled.p`
  flex-basis: 100%;
  font-size: ${theme.fontSizes.xs};
`

const StorySummaryTitle = styled.h1`
  color: ${props => props.listViewEnabled ? theme.colors.black : theme.colors.white};
  font-size: ${theme.fontSizes.l};
  margin: ${theme.margin.stackS};

  @media (min-width: 768px) {
    color: ${theme.colors.black};
  }
`

const StorySummaryAuthor = styled.p`
  font-style: italic;
  font-size: ${theme.fontSizes.s};
`

export { 
  StorySummaryBlock, 
  StorySummaryDismissContainer, 
  StorySummaryContentContainer,
  StorySummaryInfoContainer,
  StorySummaryDate,
  StorySummaryTitle,
  StorySummaryAuthor 
}