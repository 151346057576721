import React from "react"
import { ActionSet, ActionSetAction } from "../styles/05-components/actionSetStyles"

export default props => {
  return (
    <ActionSet>
      {
        props.children.length > 1 ?
          props.children.map((child, i) => 
            <ActionSetAction key={i}>{child}</ActionSetAction>
          )
        : <ActionSetAction>{props.children}</ActionSetAction>
      }
    </ActionSet>
  )
}
