import React from "react"

import styled from "styled-components"
import theme from "../styles/01-settings/theme"
import { 
  HeaderBlock, 
  HeaderMenuButton, 
  HeaderLogoContainer 
} from "../styles/05-components/headerStyles"

import Icon from "./Icon"
import UtilityNav from "./UtilityNav"
import Logo from "./Logo"
import MainNav from "./MainNav"

const StyledMainNav = styled(MainNav)`
  display: none;

  @media (min-width: 768px) {
    color: ${theme.colors.black};
    display: block;
    flex-basis: auto;
    margin: ${theme.margin.stack};
    justify-content: space-between;
  }

  @media (min-width: 992px) {
    flex-basis: auto;
  }
`

export default function Header(props) {
  const menuPanelOpen = props.menuPanelOpen

  function toggleMenu(status) {
    props.onMenuChange(!status)
  }

  function handleAboutClick(status) {
    props.aboutInfoChange(!status)
  }
  
  function handleListViewClick(status) {
    props.onListViewChange(!status)
  }

  function handleAllStoriesClick() {
    props.allStoriesClick()
  }

  return (
    <HeaderBlock isListPage={props.isListPage}>
      <HeaderMenuButton onClick={(e) => { toggleMenu(menuPanelOpen) }} aria-label="Menu Toggle">
        <Icon>menu</Icon>
      </HeaderMenuButton>
      <HeaderLogoContainer>
        <Logo allStoriesClick={handleAllStoriesClick} />
      </HeaderLogoContainer>
      <UtilityNav 
        filters={props.children} 
        isListPage={props.isListPage} 
        onListViewChange={handleListViewClick} 
        listViewEnabled={props.listViewEnabled}
        isTrailOrProject={props.isTrailOrProject}></UtilityNav>
      <StyledMainNav 
        allStoriesClick={props.allStoriesClick}
        aboutClick={handleAboutClick} />
    </HeaderBlock>
  )
}
