import React from "react"
import styled from "styled-components"
import theme from "../styles/01-settings/theme"

import LogoFile from "../assets/logos/logo.svg"

import { LogoBlock, LogoImage } from "../styles/05-components/logoStyles"

const StyledLinkButton = styled.button`
  appearance: none;
  align-items: center;
  background: transparent;
  border: 0;
  color: ${theme.colors.black};
  display: flex;
  text-decoration: none;

  &:visited {
    color: ${theme.colors.black};
  }

  &:hover {
    color: ${props => props.theme.accentColorHover};
  }
`

export default function Logo(props) {
  return (
    <LogoBlock>
      <StyledLinkButton onClick={(e) => { props.allStoriesClick() }}  className="no-border">
        <LogoImage src={LogoFile}></LogoImage>
      </StyledLinkButton>
    </LogoBlock>
  )
}
