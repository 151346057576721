import styled from "styled-components"
import theme from "../01-settings/theme"
import { rgba } from "../02-tools/rgba"

import SRHeading from "../06-utilities/srHeading"

const FilterBlock = styled.section`
  background-color: ${theme.colors.darkGrey};
  color: white;
  padding: ${theme.padding.insetS};
`

const FilterHeading = styled(SRHeading)``

const FilterSearch = styled.div``

const FilterLabel = styled.label``

const FilterInputGroup = styled.div`
  display: flex;
  margin: ${theme.margin.stackS};

  button {
    background: ${props => props.theme.accentColor};
    border: 0;
    color: ${theme.colors.white};

    &:hover {
      background: ${props => props.theme.accentColorHover};
      cursor: pointer;
    }
    
    &:active {
      background: ${props => props.theme.accentColorActive};
    }
  }
`

const FilterInput = styled.input`
  appearance: none;
  border: 0;
  padding: ${theme.padding.insetS};
  width: 100%;
`

const FilterThemes = styled.div`
  margin: ${theme.margin.stackXL};
`

const FilterThemeList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const FilterThemeItem = styled.li`
  display: inline-block;
  margin-right: ${theme.spacing.s};
  margin-top: ${theme.spacing.s};

  &:last-of-type {
    margin-right: 0;
  }
`

const FilterThemeButton = styled.button`
  background-color: ${props => props.isActive ? props.theme.accentColor : rgba(theme.colors.white, 0.1)};
  border: 0;
  border-radius: ${props => props.nav ? "100%" : "5px"};
  color: ${theme.colors.white};
  display: inline-block;
  padding: ${theme.padding.insetXS};

  &:hover {
    background-color: ${props => props.isActive ? props.theme.accentColorHover : rgba(theme.colors.white,0.3)};
  }
  
  &:hover {
    background-color: ${props => props.isActive ? props.theme.accentColorActive : rgba(theme.colors.white,0.4)};
  }
`


export { FilterBlock, FilterHeading, FilterSearch, FilterLabel, FilterInputGroup, FilterInput, FilterThemes, FilterThemeList, FilterThemeItem, FilterThemeButton }