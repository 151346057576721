import styled from "styled-components"
import theme from "../01-settings/theme"
import { rgba } from "../02-tools/rgba"

const StoryListContainer = styled.div`
    overflow-y: scroll;

    ::-webkit-scrollbar {
        appearance: none;
        width: 16px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: ${theme.colors.lightGrey};
        border: 3px solid white;
    }
`

const StoryListBlock = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`

const StoryListItem = styled.li`
    background-color: ${props => props.selected ? `${rgba(props.theme.accentColor, 0.2)}` : "transparent"};
    border-bottom: 1px solid;
    border-bottom-color: ${props => props.theme.accentColor};
    padding: ${theme.padding.insetS};

    &:first-of-type {
        margin: 0;
        padding-top: 0;
    }

    &:last-of-type {
        border-bottom: 0;
    }

    * {
        margin: 0;
    }
`

export { StoryListContainer, StoryListBlock, StoryListItem }