import styled from "styled-components"
import theme from "../01-settings/theme"

const SocialActionsBlock = styled.section`
    margin: ${theme.margin.stackL};
`

const SocialActionsHeading = styled.h1`
  font-size: ${theme.fontSizes.s};
  margin: 0;
`
const SocialActionsList = styled.ul`
    list-style: none;
    padding: 0;
`
const SocialActionsItem = styled.li`
    display: inline-block;
    margin: ${theme.margin.inline};

    &:last-of-type {
        margin: 0;
    }
`

export { SocialActionsBlock, SocialActionsHeading, SocialActionsList, SocialActionsItem }
