import React, { Fragment, useState } from "react"

import { UtilityNavBlock, UtilityNavItem, UtilityNavButton, UtilityNavMenu, UtilityNavMenuList, UtilityNavMenuItem, UtilityNavMenuButton, UtilityNavFilters } from "../styles/05-components/utilityNavStyles"

import Icon from "./Icon"

export default function UtilityNav(props) {
  const [utilityMenuOpen, toggleUtilityMenu] = useState(false);
  const [utilityFiltersOpen, toggleUtilityFilters] = useState(false);

  function handleUtilityToggle(item) {
    switch (item) {
      case "filters":
        toggleUtilityMenu(false)
        toggleUtilityFilters(!utilityFiltersOpen)
        break;
      case "menu":
        toggleUtilityFilters(false)
        toggleUtilityMenu(!utilityMenuOpen)
        break;    
      default:
        break;
    }
  }

  function handleListViewToggle() {
    props.onListViewChange()
    toggleUtilityMenu(!utilityMenuOpen)
  }

  function showSearchAndMore(props) {
    if(props.isListPage) {
      return !props.isListPage ? true : false
    } else if(props.isTrailOrProject) {
      return !props.isTrailOrProject ? true : false
    } else {
      return true
    }
  }

  return (
    <Fragment>
      <UtilityNavBlock>
        {
          showSearchAndMore(props) &&
            <Fragment>
              <UtilityNavItem>
                <UtilityNavButton onClick={(e) => { handleUtilityToggle("filters") }}>
                  <Icon>search</Icon>
                </UtilityNavButton>
              </UtilityNavItem>
              <UtilityNavItem>
                <UtilityNavButton onClick={(e) => { handleUtilityToggle("menu") }}>
                  <Icon>more_vert</Icon>
                </UtilityNavButton>
              </UtilityNavItem>
            </Fragment>
        }
      </UtilityNavBlock>

      <UtilityNavMenu open={utilityMenuOpen}>
        <UtilityNavMenuList>
          <UtilityNavMenuItem>
            <UtilityNavMenuButton onClick={(e) => { handleListViewToggle() }}>
              {
                props.listViewEnabled ? "Switch to Map View" : "Switch to List View"
              }
            </UtilityNavMenuButton>
          </UtilityNavMenuItem>
        </UtilityNavMenuList>
      </UtilityNavMenu>
    
      <UtilityNavFilters open={utilityFiltersOpen}>
        {props.filters}
      </UtilityNavFilters>
    </Fragment>
  )
}
