import styled from "styled-components"
import theme from "../01-settings/theme"
import SRHeading from "../06-utilities/srHeading"
import { rgba } from "../02-tools/rgba"

const StoryBlock = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: ${theme.padding.insetL};

  @media screen and (min-width: 992px) {
    flex-direction: row;
    padding: 0;
  }
`

const StoryHeader = styled.header`
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
`

const StoryActionDate = styled.div`
  display: flex;
  flex-basis: 100%;
  width: 100%;

  * {
    margin: 0;
  }
`

const StoryAction = styled.p`
  flex-grow: 1;
  font-size: ${theme.fontSizes.xs};
`

const StoryDate = styled.p`
  align-items: center;
  display: flex;
  font-size: ${theme.fontSizes.xs};

  .material-icons {
    margin-right: ${theme.spacing.xs};
  }
`

const StoryAuthorAddress = styled.section`
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  margin: ${theme.margin.stackL};
  width: 100%;

  * {
    margin: 0;
  }
`

const StoryAuthor = styled.section`
  align-items: center;
  display: flex;
  margin: ${theme.margin.stack};
`

const StoryAuthorImage = styled.img`
  box-shadow: 0 0 0 2px ${rgba(theme.colors.white, 0.08)};
  border-radius: 4px;
  height: 100px;
  margin: ${theme.margin.inline};
  width: 100px;
`

const StoryAuthorName = styled.h1`
  font-size: ${theme.fontSizes.base};
  margin: ${theme.margin.stack};
`

const StoryAuthorHomeTown = styled.p`
  font-size: ${theme.fontSizes.xs};
  font-style: italic;
`

const StoryAddress = styled.section`
  text-align: left;
`

const StoryAddressTitle = styled.h2`
  font-size: ${theme.fontSizes.base};
  margin: ${theme.margin.stackXS};
`

const StoryContent = styled.section`
  margin: ${theme.margin.stackXL};
`

const StoryTitle = styled.h1`
  margin: 0;
`

const StoryText = styled.div`
  font-size: ${theme.fontSizes.l};
  margin: ${theme.margin.stack};
`

const StoryExtra = styled.p`
  font-style: italic;
  margin: ${theme.margin.stackL};
`

const StoryShareAction = styled.a``

const StoryFooter = styled.footer`
  background: ${props => props.theme.gradient};
  margin: 0 -${theme.spacing.l} -${theme.spacing.l} -${theme.spacing.l};
  padding: ${theme.padding.insetL};

  * {
    color: ${theme.colors.white};
  }
  
  @media (min-width: 992px) {
    flex-grow: 1;
    margin-right: 0;
    min-height: 100vh;
    padding: ${theme.spacing.l} ${theme.spacing.l} 5rem ${theme.spacing.l};
    position: relative;
  }

  @media (min-width: 1200px) {
    padding: 6rem 0 5rem 6rem;
  }
`

const StoryInfo = styled.div`
  background-color: ${theme.colors.darkGrey};
  padding: ${theme.padding.inset};

  @media (min-width: 992px) {
    box-shadow: 0 0 0 4px ${rgba(theme.colors.white, 0.08)};
    border-radius: 4px;
    max-width: 400px;
    padding: 4rem;
    position: sticky;
    top: 6.2rem;
  }
`

const StoryFooterHeading = styled(SRHeading)``

const StoryRelatedStories = styled.section`
  margin: ${theme.margin.stackXL};
`

const StoryRelatedStoriesHeading = styled.h1`
  font-size: ${theme.fontSizes.s};
`
const StoryRelatedStoriesList = styled.ul`
  list-style-type: none;
  margin: ${theme.margin.stackS};
  padding: 0;
`

const StoryRelatedStory = styled.li`
  margin: ${theme.margin.stack};
    
  * {
    margin: 0;
  }
`


export { 
  StoryBlock, 
  StoryHeader, 
  StoryActionDate,
  StoryAction,
  StoryDate,
  StoryAuthorAddress,
  StoryAuthor,
  StoryAuthorImage,
  StoryAuthorName,
  StoryAuthorHomeTown,
  StoryAddress,
  StoryAddressTitle,
  StoryContent, 
  StoryTitle,
  StoryText,
  StoryExtra,
  StoryFooter,
  StoryInfo,
  StoryShareAction,
  StoryFooterHeading,
  StoryRelatedStories,
  StoryRelatedStoriesHeading,
  StoryRelatedStoriesList,
  StoryRelatedStory
}