import React, { useState, useEffect, Fragment } from "react"
import { useAsync } from "react-async"
import _ from "lodash"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { getListItems } from "../api/storymapApi"

import theme from "../styles/01-settings/theme"
import { 
  ListPageBlock, 
  ListPageContent, 
  ListPageHeader, 
  ListPageHeading, 
  List, 
  ListItem, 
  ListItemHeader, 
  ListItemHeading, 
  ListItemCount, 
  ListItemDescription 
} from "../styles/05-components/listPageStyles"

import Loader from "./Loader"

const StyledListLink = styled(Link)`
  border: 0;
  color: ${theme.colors.black};
  display: block;
  padding: ${theme.spacing.base} 0;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: ${theme.colors.black};
  }
`

export default function ListPage(props) {
  const apiPath = props.list
  const title = props.list
  const { data, error, isPending } = useAsync({ promiseFn: getListItems, apiPath, watch: apiPath })
  const queryPath = props.list === "projects" ? "project" : "trail"
  const [isLoading, setIsLoading] = useState(false)

  const listItems = _.sortBy(data, ['name'])

  useEffect(() => {
    if(isPending) {
      setIsLoading(true)
    } else {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [isPending])

  if(!data || error) {
    // TODO: should probably add a loading state or something
    return null;
  }

  return (
    <Fragment>
      { isLoading ? <Loader />
        : <ListPageBlock>
            <ListPageContent>
                {
                  Array.isArray(listItems) && listItems.length > 0 ?
                    <Fragment>
                      <ListPageHeader><ListPageHeading>{title}</ListPageHeading></ListPageHeader>
                      <List>
                      {
                        listItems.map((item, index) => {
                          let itemTitle = item.name.includes("&amp;") ? item.name.replace("&amp;", "&") : item.name

                          return (
                                <ListItem key={index}>
                                  <StyledListLink to={`/stories?${queryPath}=${item.slug}`} >
                                    <ListItemHeader>
                                      <ListItemHeading>{itemTitle}</ListItemHeading>
                                      <ListItemCount>{item.story_count} Stories</ListItemCount>
                                    </ListItemHeader>
                                  </StyledListLink>
                                  {
                                    item.description &&
                                    <ListItemDescription dangerouslySetInnerHTML={{__html: item.description}}></ListItemDescription>
                                  }
                                </ListItem>
                          )
                        })
                      }
                      </List>
                    </Fragment>
                  : <ListPageHeader><ListPageHeading>Currently No Trails</ListPageHeading></ListPageHeader>
                }
            </ListPageContent>
          </ListPageBlock>
      }
    </Fragment>
  )
}
