import React from "react"
import styled from "styled-components"
import theme from "../styles/01-settings/theme"

const Address = styled.address`
  font-size: ${theme.fontSizes.s};
`

const Street = styled.span`
  :nth-child(2) {
    margin: 0;
  }
`
const CityStateZip = styled.span`
  margin-top: 0;
`

export default props => 
  <Address className={props.className}>
    {
      props.address.street ? 
        <Street>{props.address.street},</Street> :
        null
    }
    {
      props.address.city && props.address.stateZip ?
        <CityStateZip>{props.address.city}, {props.address.stateZip}</CityStateZip> :
        null
    }
  </Address>