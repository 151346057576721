import React, { useState, useEffect } from 'react'
import ReactMapGL, { Marker } from 'react-map-gl'
import styled from 'styled-components'

import { MarkerButton } from "../styles/05-components/mapStyles"

import Icon from "./Icon"
import StorySummary from "./StorySummary"

const StyledMarker = styled(Marker)`
  &.selected {
    z-index: 1;
  }
`

export default function Map(props) {
  const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN
  const mapStyle = process.env.REACT_APP_MAPBOX_URL

  useEffect(() => {
    if(props.selectedStory && Object.keys(props.selectedStory).length !== 0) {
      setPopupState(true)
      setViewport({
        latitude: props.selectedStory.location.lat,
        longitude: props.selectedStory.location.lng,
        zoom: 12,
        transitionDuration: 500
      })
    } else {
      setPopupState(false)
    }
  }, [props.selectedStory])
  
  const [viewport, setViewport] = useState({
    latitude: 34.0007,
    longitude: -81.0348,
    zoom: 12,
    transitionDuration: 500
  })
  
  const [showPopup, setPopupState] = useState(false)

  const stories = props.stories
  const selectedStory = props.selectedStory

  function displayStorySummary(story) {
    props.onSelectedStoryChange(story)
    setViewport({
      latitude: story.location.lat,
      longitude: story.location.lng,
      zoom: 12,
      transitionDuration: 500
    })
    setPopupState(true)
  }

  function handleDismiss() {
    setPopupState(false)
    props.onSelectedStoryChange({})
  }

  return (
    <ReactMapGL
      {...viewport}
      isTrail={props.isTrail}
      height="100vh"
      width="100%"
      mapStyle={mapStyle}
      mapboxApiAccessToken={TOKEN}
      onViewportChange={(nextViewport) => setViewport(Object.assign({}, nextViewport))}>
      {
        showPopup && 
        <StorySummary 
          storyIndex={props.selectedStoryIndex}
          trailCount={props.stories.length}
          onTrailNext={props.onTrailNext}
          onTrailPrev={props.onTrailPrev}
          dismissible 
          isTrail={props.isTrail}
          onDismiss={ (e) => { handleDismiss() }} 
          story={selectedStory}
        ></StorySummary>
      }
      {
        Array.isArray(stories) && stories.length > 0 ?
          stories.map((story, index) => {
            return (
              (story.location && story.location.lat && story.location.lng) ?
                <StyledMarker
                  key={index}
                  latitude={story.location.lat}
                  longitude={story.location.lng}
                  className={props.selectedStory === story ? "selected" : null}>
                    <MarkerButton onClick={(e) => { displayStorySummary(story) }}>
                      {
                        props.selectedStory === story ?
                          <Icon className="selected">location_on</Icon>
                        : <Icon className="black">location_on</Icon>
                      }
                    </MarkerButton>
                </StyledMarker>
              : null
            )
          })
        : null
      }
    </ReactMapGL>
  )
}
