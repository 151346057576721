import React from "react"
import { Link, useLocation } from "react-router-dom"

import styled from "styled-components"
import theme from "../styles/01-settings/theme"
import { rgba } from "../styles/02-tools/rgba"
import { 
    StorySummaryBlock, 
    StorySummaryDismissContainer, 
    StorySummaryContentContainer,
    StorySummaryInfoContainer,
    StorySummaryDate,
    StorySummaryTitle,
    StorySummaryAuthor 
} from "../styles/05-components/storySummaryStyles"

import Icon from "./Icon"
import Button from "./Button"

const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  color: ${theme.colors.white};
  justify-content: space-between;
  margin: ${props => props.isTrail ? "0" : theme.margin.stackL};
  text-decoration: none;
  width: 100%;

  padding: ${theme.padding.insetS};
  padding-bottom: ${props => props.isTrail ? theme.spacing.s : theme.spacing.base};

  &:visited {
    color: ${theme.colors.white};
  }

  &[isTrail] {
    &:visited, &:hover, &:focus, &:active {
      color: ${theme.colors.white};

      @media (min-width: 768px) { 
        color: ${theme.colors.black};
      }
    }
  }

  &[listviewenabled] {
    color: ${theme.colors.black};
  
    &:visited, &:hover, &:focus, &:active {
      color: ${theme.colors.black};
    }
  }



  ${StorySummaryAuthor} {
    flex-basis: 100%;
  }

  @media (min-width: 768px) {
    color: ${theme.colors.black};

    &:visited {
      color: ${theme.colors.black};
    }
  }
`

const StyledIcon = styled(Icon)`
  display: ${props => props.isTrail ? "none" : "block"};
`

const StyledButton = styled(Button)`
  background-color: ${rgba(theme.colors.white, 0.7)};
  border: 0;
  display: ${props => props.isTrail ? "block" : "none"};
  flex-basis: 5%;

  &:first-of-type {
    border-radius: 5px 0 0 5px;
  }
  
  &:last-of-type {
    border-radius: 0 5px 5px 0;
  }

  @media (min-width: 768px) {
    display: none;
  }
`

const TrailCount = styled.p`
  display: ${props => props.isTrail ? "block" : "none"};
  flex-basis: 100%;
  font-size: ${theme.fontSizes.s};
  margin: 0;
  padding-bottom: ${theme.spacing.s};
  text-align: center;
`

export default function StorySummary(props) {
  const { story } = props
  const location = useLocation()
  const handleClick = props.onDismiss
  const handleNext = props.onTrailNext
  const handlePrev = props.onTrailPrev

  return (
    <StorySummaryBlock className={props.className}>
      <StyledButton isTrail={props.isTrail} onClick={handlePrev}><Icon>keyboard_arrow_left</Icon></StyledButton>
      <StorySummaryContentContainer listViewEnabled={props.listViewEnabled} isTrail={props.isTrail}>
        {
          props.dismissible &&
          <StorySummaryDismissContainer isTrail={props.isTrail}>
            <button onClick={handleClick}><Icon className="white">close</Icon></button>
          </StorySummaryDismissContainer>
        }
        <StyledLink 
          className="no-border" 
          listviewenabled={props.listViewEnabled ? "true" : undefined}
          istrail={`${props.isTrail}`}
          to={
            {
              pathname: `/story/${story.slug}`,
              state: {
                previousLocation: `${location.pathname}${location.search}${location.hash}`
              }
            }
          }
        >
          <StorySummaryInfoContainer>
            {
              story.creation_date && <StorySummaryDate isTrail={props.isTrail}>{story.creation_date}</StorySummaryDate>
            }
            <StorySummaryTitle listViewEnabled={props.listViewEnabled}>{story.title}</StorySummaryTitle>
            <StorySummaryAuthor>
              {story.authors ? story.authors.map(author => author.name).join(', ') : null}
            </StorySummaryAuthor>
          </StorySummaryInfoContainer>
          <StyledIcon isTrail={props.isTrail}>keyboard_arrow_right</StyledIcon>
        </StyledLink>
        <TrailCount isTrail={props.isTrail}>{props.storyIndex + 1} of {props.trailCount}</TrailCount>
      </StorySummaryContentContainer>
      <StyledButton isTrail={props.isTrail} onClick={handleNext}><Icon>keyboard_arrow_right</Icon></StyledButton>
    </StorySummaryBlock>
  )
}
