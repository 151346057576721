import React from "react"
import { Link } from "react-router-dom"

import styled from "styled-components"
import theme from "../styles/01-settings/theme"
import { MainNavBlock, MainNavItem } from "../styles/05-components/mainNavStyles"

import Icon from "./Icon"

const StyledLink = styled(Link)`
  align-items: center;
  color: ${theme.colors.black};
  display: flex;
  text-decoration: none;

  &:visited {
    color: ${theme.colors.black};
  }

  &:hover {
    color: ${props => props.theme.accentColorHover};
  }
`

const StyledLinkButton = styled.button`
  appearance: none;
  align-items: center;
  background: transparent;
  border: 0;
  color: ${theme.colors.black};
  display: flex;
  font-size: ${theme.fontSizes.base};
  padding: 0;
  text-decoration: none;

  &:visited {
    color: ${theme.colors.black};
  }

  &:hover {
    color: ${props => props.theme.accentColorHover};
  }
`

const StyledIcon = styled(Icon)`
  margin: ${theme.margin.inlineXS};

  &:hover {
    color: ${props => props.theme.accentColorHover};
  }
`

export default function MainNav(props) {
  return (
    <MainNavBlock className={props.className}>
      <MainNavItem>
        <StyledLinkButton onClick={(e) => { props.allStoriesClick() }} className="no-border">
          <StyledIcon>location_on</StyledIcon> All Stories
        </StyledLinkButton>
      </MainNavItem>
      <MainNavItem>
        <StyledLink to="/trails" className="no-border">
          <StyledIcon>map</StyledIcon>Trails
        </StyledLink>
      </MainNavItem>
      <MainNavItem>
        <StyledLink to="/projects" className="no-border">
          <StyledIcon>view_quilt</StyledIcon>Projects
        </StyledLink>
      </MainNavItem>
      <MainNavItem>
        <StyledLinkButton onClick={(e) => { props.aboutClick(true) }} className="no-border">
          <StyledIcon>info</StyledIcon>About
        </StyledLinkButton>
      </MainNavItem>
    </MainNavBlock>
  )
}