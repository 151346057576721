import styled from "styled-components"

const SRHeading = styled.h1`
    border: 0; 
    clip: rect(0,0,0,0);
    color: #000000;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    visibility: hidden;
`

export default SRHeading
