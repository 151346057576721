import styled from "styled-components"
import theme from "../01-settings/theme"

const MenuBlock = styled.nav`
  background-color: ${theme.colors.white};
  bottom: 0;
  color: ${theme.colors.black};
  display: ${props => props.open ? "flex" : "none"};
  flex-direction: column;
  left: 0;
  padding: ${theme.spacing.base} ${theme.spacing.xxl} ${theme.spacing.l} ${theme.spacing.l};
  position: absolute;
  top: 0;
  z-index: 1;

  @media (min-width: 768px) {   
    display: none;
  }
`

const MenuLogoContainer = styled.div``

export { MenuBlock, MenuLogoContainer }