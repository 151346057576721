import React from "react"
import ReactPlayer from "react-player"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children; 
  }
}


export default (props) => {
  const { videos, audio, images } = props
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  }

  return (
    <ErrorBoundary>
      <div style={{maxWidth: `100%`}}>
        <Slider {...settings} style={{marginBottom: `6.7772rem`}}>
          {
            Array.isArray(videos) && videos.length > 0 ?
              videos.map((video, index) =>
                video.vimeo_id ?
                  <div class="slide-wrapper">
                    <ReactPlayer key={index}
                      url={`https://vimeo.com/${video.vimeo_id}`}
                      style={{width: `100%`, maxHeight: `100%`}}
                      width={`100%`}
                      controls
                    />
                    {
                      video.video_caption &&
                      <div dangerouslySetInnerHTML={{__html: video.video_caption}}></div> 
                    }
                  </div>
                  : null
              )
            : null
          }
          {
            Array.isArray(audio) && audio.length > 0 ?
              audio.map((aud, index) =>
                aud.soundcloud_id ?
                  <div class="slide-wrapper">
                    <ReactPlayer key={index}
                      url={aud.soundcloud_id}
                      style={{width: `100%`, maxHeight: `100%`}}
                      width={`100%`}
                      controls
                    />
                    {
                      aud.audio_caption &&
                      <div dangerouslySetInnerHTML={{__html: aud.audio_caption}}></div> 
                    }
                  </div>
                  : null
              )
            : null
          }
          {
            Array.isArray(images) && images.length > 0 ?
              images.map((image, index) =>
                image.image ?
                  <div class="slide-wrapper">
                    <img
                      key={index}
                      src={image.image}
                      alt={image.image_caption}
                      style={{width: `100%`, maxHeight: `100%`}}
                    />
                    {
                      image.image_caption &&
                      <div dangerouslySetInnerHTML={{__html: image.image_caption}}></div> 
                    }
                  </div>
                  : null
              )
            : null
          }
        </Slider>
      </div>
    </ErrorBoundary>
  )
}