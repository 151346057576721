import styled from "styled-components"
import theme from "../01-settings/theme"

const PageContent = styled.section`
  display: flex;
  flex-direction: ${props => props.listViewEnabled ? "column" : "row"};
  margin-left: ${props => props.menuOpen ? "234px" : "0px"};

  @media (min-width: 768px) {   
    margin-left: 0px;
  }
`

const PrimaryContainer = styled.div`
  position: relative;
  max-height: 100vh;
  min-height: 100vh;
  width: 100%;

  @media (min-width: 768px) {
    flex-grow: 1;
  }
`

const SecondaryContainer = styled.div`
  display: ${props => props.listViewEnabled ? "block" : "none"};

  @media (min-width: 768px) {   
    background: ${props => props.theme.gradient};
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    min-width: 500px;
    padding: ${theme.padding.inset};
  }
`
export { PageContent, PrimaryContainer, SecondaryContainer }