import React from "react"

import {    
  FilterBlock, 
  FilterHeading,
  FilterSearch,
  FilterLabel,
  FilterInputGroup,
  FilterInput,
  FilterThemes,
  FilterThemeList, 
  FilterThemeItem, 
  FilterThemeButton 
} from "../styles/05-components/filterStyles"

import ActionSet from "./ActionSet"
import Button from "./Button"
import Icon from "./Icon"

export default function Filter(props) {
  const themes = props.themes
  const filteredThemes = props.filteredThemes
  // const x = {...props}
  const id = props.id

  return (
    <FilterBlock className={props.className}>
      <FilterHeading>Filter Stories</FilterHeading>
      <FilterSearch>
        <FilterLabel htmlFor={`${id}Search`}>Search</FilterLabel>
        <FilterInputGroup>
          <FilterInput type="text" id={`${id}Search`} onChange={event => props.onSearchTermChange(event.target.value)} value={props.searchTerm} />
          <button><Icon>search</Icon></button>
        </FilterInputGroup>
      </FilterSearch>
      <FilterThemes>
        <p>Show stories tagged with:</p>
        <FilterThemeList>
          {
            Array.isArray(themes) && themes.length > 0  ?
              themes.map((theme, index) =>
                <FilterThemeItem key={index}>
                  <FilterThemeButton 
                    isActive={filteredThemes.includes(theme.slug)}
                    onClick={(e) => { props.onFilteredThemesChange(theme.slug) }}
                  >{theme.name}</FilterThemeButton>
                </FilterThemeItem>
              )
            : null
          }
        </FilterThemeList>
      </FilterThemes>
      <ActionSet>
        <Button isActionSet onClick={(e) => { props.onResetFilters() }}>Reset Filters</Button>
        {/* <Button isActionSet primary onClick={(e) => { props.onApplyFilters(x.props) }}>Apply Filters</Button> */}
      </ActionSet>
    </FilterBlock>
  )
}
