import React, { Fragment } from "react"
import { DefaultButton, ActionButton } from "../styles/05-components/buttonStyles"

export default function Button(props) {
  return (
    <Fragment>
      { props.isActionSet 
        ? <ActionButton {...props} href={props.url}>{props.children}</ActionButton>
        : <DefaultButton {...props} href={props.url}>{props.children}</DefaultButton>
      }
    </Fragment>
  )
}
