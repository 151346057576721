export default {
  lightGrey: "#c2c2c2",
  darkGrey: "#231F20",
  white: "#fff",
  black: "#000",
  pink: "#ec008d",
  red: "#F0184D",
  lightGreen: "#D3DC46",
  lightBlue: "#1EE7FF",
  mutedPink: "#D17151",
  mutedPurple: "#C1828E",
  mutedGold: "#C48E46",
  mutedDarkPurple: "#7A3241",
  purple: "#92006B",
  purpleHover: "#C2008E",
  purpleHoverInverse: "#FFD6F4",
  purpleActive: "#D6009D",
  darkGreen: "#4B8226",
  darkGreenHover: "#38631D",
  darkGreenActive: "#2A4A17",
  blue: "#007EA8",
  blueHover: "#006B8F",
  blueActive: "#005C7A",
  mutedRed: "#B9463C",
  mutedRedHover: "#9E3C33",
  mutedRedHoverInverse: "#D8A097",
  mutedRedActive: "#92382F",
  brightPurple: "#D6009D",
  brightRed: "#CA2B1D",
  brightGreen: "#54B116",
  brightBlue: "#069ACB"
}
