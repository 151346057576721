import React, { Fragment, useState, useEffect, useReducer } from "react"
import _ from "lodash"
import queryString from "query-string"
import { useAsync } from "react-async"
import { useHistory, Link } from "react-router-dom"
import { apiHelper } from "../../api/apiHelper"
import useQuery from "../../hooks/useQuery"
import { getStories } from "../../api/storymapApi"

import styled from "styled-components"
import theme from "../../styles/01-settings/theme"
import { PageContent, PrimaryContainer, SecondaryContainer } from "../../styles/05-components/homePageStyles"
import { rgba } from "../../styles/02-tools/rgba"

import Map from "../Map"
import StoryList from "../StoryList"
import Header from "../Header"
import MenuPanel from "../MenuPanel"
import Filter from "../Filter"
import Dialog from "../Dialog"
import Button from "../Button"
import Icon from "../Icon"
import Loader from "../Loader"

const StyledStoryList = styled(StoryList)`
  background-color: ${theme.colors.white};
  padding: ${theme.padding.inset}; 
  padding-right: 0;
`

const StyledFilter = styled(Filter)`
  padding: ${theme.spacing.l} ${theme.spacing.base};
`

const StyledLink = styled(Link)`
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.s};

  &:hover,
  &:focus {
    color: ${theme.colors.white};
  }
`

const ListInfo = styled.section`
  background-color: ${theme.colors.darkGrey};
  display: none;
  flex-direction: column;
  padding: ${theme.padding.inset};

  h2 {
    color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    font-family: ${theme.fontFamily.primaryText};
    font-size: ${theme.fontSizes.l};
    font-weight: ${theme.fontWeights.normal};

    span:first-child {
      font-size: ${theme.fontSizes.base};
    }

    span:nth-child(2) {
      margin: ${theme.margin.stackXS};
    }
  }

  ${StyledLink} {
    align-self: flex-end;
    margin: ${theme.margin.stack};
  }

  @media (min-width: 768px) {
    display: flex;
  }
`

const ListInfoMobile = styled.section`
  background-color: ${theme.colors.darkGrey};
  padding: ${theme.padding.inset};
  display: flex;
  justify-content: space-between;

  h2 {
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.primaryText};
    font-size: ${theme.fontSizes.l};
    font-weight: ${theme.fontWeights.normal};
  }

  Button {
    padding: ${theme.padding.insetXS};
  }

  @media (min-width: 768px) {
    display: none;
  }
`

const ListInfoHeader = styled.header`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  h2 {
    flex-grow: 1;
    margin: 0;
  }

  ${StyledLink} {
    align-self: flex-start;
    margin: 0;
    order: 2;
  }
`

const TrailActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${theme.margin.stackL};

  Button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  Button:first-child {
    margin: ${theme.margin.inlineL};
  }

  Button:hover {
    background-color: ${rgba(theme.colors.white,0.3)};
  }
`

const HeaderContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
`

export default function Home(props) {
  const query = useQuery()
  const history = useHistory()
  let location = props.location.search
  const { data, error, isPending } = useAsync({ promiseFn: getStories, props, query, watch: location }) 
  const [selectedStory, setSelectedStory] = useState({})
  const [menuPanelOpen, toggleMenuPanel] = useState(false)
  const [aboutInfoOpen, toggleAboutInfo] = useState(false)
  const [listViewEnabled, toggleListView] = useState(false)
  const [searchTerm, setSearchTerm] = useState(``)
  const [availableThemes, setAvailableThemes] = useState([])
  const [filteredThemes, dispatchFilteredThemes] = useReducer(filteredThemesReducer, [])
  const [filteredStories, setFilteredStories] = useState([])
  const [isTrailOrProject, setIsTrailOrProject] = useState(false)
  const [isTrail, setIsTrail] = useState(false)
  const [listPageInfo, setListPageInfo] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchAllStories() {
      const results = await apiHelper(`wp-json/storymap/v1/stories`);
      compileThemes(results)
    }
    
    fetchAllStories()
  }, []);

  useEffect(() => dispatchFilteredThemes({type: 'initialize'}), []) 

  useEffect(() => {
    function storyHasTermEffect(story, term) {
      let titleHasTerm = stringHasTerm(story.title, term)
      let authorHasTerm = story.authors.filter(s => stringHasTerm(s.name, term)).length > 0
  
      return ( titleHasTerm || authorHasTerm )
    }

    function applyFiltersEffect() {
      if(data) {
        let stories = [...data]
  
        if(filteredThemes.length > 0) {
          let queryString = "?"
          
          filteredThemes.forEach((theme, index) => {
            index > 0 ? queryString += `&theme=${theme}` : queryString += `theme=${theme}`
          })
  
          history.replace({
            pathname:'/stories',
            search: queryString
          })
  
          stories = stories.filter(story => story.themes.some(theme => filteredThemes.includes(theme.slug)))
        }
  
        if(searchTerm.length > 0) {
          stories = stories.filter(story => storyHasTermEffect(story, searchTerm))
        }

        setFilteredStories(stories)
      }
    }

    if(history.location && (queryString.parse(history.location.search).trail || queryString.parse(history.location.search).project)) {
      setIsTrailOrProject(true)

      if(queryString.parse(history.location.search).trail) {
        setIsTrail(true)

        if(data) {
          setSelectedStory(data[0])
        }
      }
    } else if(history.location && (!queryString.parse(history.location.search).trail || !queryString.parse(history.location.search).project) && isTrailOrProject) { 
      setSelectedStory({})
      setIsTrail(false)
      setIsTrailOrProject(false)
    }

    applyFiltersEffect()
  }, [history, data, filteredThemes, searchTerm, isTrailOrProject])

  useEffect(() => {
    async function fetchListData() {
      let apiPath, listName, result
  
      if(history.location && queryString.parse(history.location.search).trail) {
        apiPath = "trails"
        listName = queryString.parse(history.location.search).trail
        setIsTrail(true)

        if(data) {
          setSelectedStory(data[0])
        }
      } else if(history.location && queryString.parse(history.location.search).project) {
        apiPath = "projects"
        listName = queryString.parse(history.location.search).project
      }

      if(apiPath) {
        const results = await apiHelper(`wp-json/storymap/v1/${apiPath}`);
        result = results.filter(result => result.slug === listName)
        setListPageInfo(result[0])
      }
    }
    
    fetchListData()
  }, [history, data, isTrailOrProject])

  useEffect(() => {
    if(Object.keys(selectedStory).length !== 0) {
      let storyId = document.getElementById(selectedStory.id) 
      return storyId ? storyId.scrollIntoView({behavior: "smooth", block: "center"}) : undefined;
    }
  }, [selectedStory])

  useEffect(() => {
    if(aboutInfoOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [aboutInfoOpen])

  useEffect(() => {
    if(isPending) {
      setIsLoading(true)
    } else {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [isPending])

  if(!data || error) {
    // TODO: should probably add a loading state or something
    return null;
  }

  function filteredThemesReducer(state, action) {
    switch (action.type) {
      case 'initialize':
        var themes

        if(props.location && queryString.parse(props.location.search).theme) {
          if(typeof queryString.parse(props.location.search).theme !== "string") {
            themes = queryString.parse(props.location.search).theme
          } else {
            themes = [queryString.parse(props.location.search).theme]
          }
        } else {
          themes = []
        }

        var lowerCaseThemes = themes.map(t => t.toLowerCase())
        return lowerCaseThemes
      case 'add':
        return [...state, action.slug]
      case 'update':
        return [...state.filter(t => t !== action.slug)]
      case 'reset':
        return []
      default:
        throw new Error()
    }
  }

  function compileThemes(x) {
    const themes = _(x).map("themes").flatten().uniqBy("name").value()
    themes.forEach(theme => {
      theme.name = theme.name.includes("&amp;") ? theme.name.replace("&amp;", "&") : theme.name;
    })
    setAvailableThemes(themes)
  }

  function handleStoryChange(story) {
    setSelectedStory(story)
  }
  
  function handleMenuChange() {
    toggleMenuPanel(!menuPanelOpen)
  }

  function handleAboutInfoChange() {
    toggleAboutInfo(!aboutInfoOpen)
  }
  
  function handleListViewChange() {
    toggleListView(!listViewEnabled)
  }

  function handleSearchTermChange(x) {
    setSearchTerm(x)
  }

  function handleFilteredThemesChange(slug) {
    if(!filteredThemes.includes(slug)) {
      dispatchFilteredThemes({type: 'add', slug })
    } else {
      dispatchFilteredThemes({type: 'update', slug})
    }
  }

  function applyFilters(props) {
    if(data) {
      let stories = [...data]

      if(filteredThemes.length > 0) {
        let queryString = "?"
        
        filteredThemes.forEach((theme, index) => {
          index > 0 ? queryString += `&theme=${theme}` : queryString += `theme=${theme}`
        })

        props.history.replace({
          pathname:'/stories',
          search: queryString
        })

        stories = stories.filter(story => story.themes.some(theme => filteredThemes.includes(theme.slug)))
      }

      if(searchTerm.length > 0) {
        stories = stories.filter(story => storyHasTerm(story, searchTerm))
      }

      setFilteredStories(stories)
    }
  }

  function stringHasTerm(str, term) {
    return term.toLowerCase().split(` `).some(word => str.toLowerCase().indexOf(word) !== -1)
  }

  function storyHasTerm(story, term) {
    let titleHasTerm = stringHasTerm(story.title, term)
    let authorHasTerm = story.authors.filter(s => stringHasTerm(s.name, term)).length > 0

    return ( titleHasTerm || authorHasTerm )
  }

  function resetFilters() {
    const stories = [...data]
    setSearchTerm(``)
    dispatchFilteredThemes({type: 'reset'})
    setFilteredStories(stories)
    props.history.replace({
      pathname: props.location.pathname,
      search: ""
    })
  }

  function handleTrailStepNext() {
    let index = data.findIndex(item => item === selectedStory)
    let next = index + 1

    if(index === data.length - 1) {
      next = 0;
    }

    setSelectedStory(data[next])
  }

  function handleTrailStepPrev() {
    let index = data.findIndex(item => item === selectedStory)
    let prev = index - 1

    if(index === 0) {
      prev = data.length - 1;
    }

    setSelectedStory(data[prev])
  }

  function getIndex(item) {
    return data.findIndex(dataItem => dataItem === item);
  }

  function showAllStories() {
    const allStoriesLocation = {
      pathname: "/",
      search: ""
    }

    dispatchFilteredThemes({type: 'reset'})
    props.history.replace(allStoriesLocation)
  }

  if (data) {
    return (
      <Fragment>
        { isLoading ?
          <Loader />
          : <Fragment>
            <MenuPanel open={menuPanelOpen} allStoriesClick={showAllStories} aboutInfoChange={handleAboutInfoChange}/>
            <PageContent menuOpen={menuPanelOpen} listViewEnabled={listViewEnabled}>
              <PrimaryContainer>
                <HeaderContainer>
                  <Header 
                    menuOpen={menuPanelOpen} 
                    onMenuChange={handleMenuChange} 
                    isListPage={props.isListPage}
                    onListViewChange={handleListViewChange}
                    listViewEnabled={listViewEnabled}
                    isTrailOrProject={isTrailOrProject}
                    listPageInfo={listPageInfo}
                    aboutInfoChange={handleAboutInfoChange}
                    allStoriesClick={showAllStories}
                  >
                    <Filter 
                      id = "mobileFilter"
                      themes={availableThemes}
                      filteredThemes={filteredThemes}
                      searchTerm={searchTerm}
                      onSearchTermChange={handleSearchTermChange}
                      onFilteredThemesChange={handleFilteredThemesChange}
                      onApplyFilters={applyFilters} 
                      onResetFilters={resetFilters} />
                  </Header>
                  {
                    (isTrailOrProject) &&
                    <ListInfoMobile>
                      <ListInfoHeader>
                        <StyledLink to="/stories">Show All Stories</StyledLink>
                        {
                          listPageInfo &&
                          <h2><span>Viewing:</span> <span>{listPageInfo.name}</span></h2>
                        }
                      </ListInfoHeader>
                    </ListInfoMobile>
                  }
                </HeaderContainer>
                {
                  !listViewEnabled &&
                    <Map 
                      {...props}
                      selectedStoryIndex={getIndex(selectedStory)}
                      isTrailOrProject={isTrailOrProject}
                      isTrail={isTrail}
                      stories={filteredStories} 
                      selectedStory={selectedStory} 
                      onSelectedStoryChange={handleStoryChange}
                      onTrailNext={handleTrailStepNext}
                      onTrailPrev={handleTrailStepPrev}
                      listViewEnabled={listViewEnabled}></Map>
                }
              </PrimaryContainer>
              <SecondaryContainer listViewEnabled={listViewEnabled}>
                {
                  (!listViewEnabled && !isTrailOrProject) &&
                    <StyledFilter 
                      props = {props}
                      id = "desktopFilter"
                      themes={availableThemes}
                      filteredThemes={filteredThemes} 
                      searchTerm={searchTerm}
                      onSearchTermChange={handleSearchTermChange}
                      onFilteredThemesChange={handleFilteredThemesChange}
                      onApplyFilters={applyFilters} 
                      onResetFilters={resetFilters} 
                      listViewEnabled={listViewEnabled} />
                }
                {
                  (!listViewEnabled && isTrailOrProject && isTrail) &&
                  <ListInfo>
                    <ListInfoHeader>
                      <StyledLink to="/stories">Show All Stories</StyledLink>
                      {
                        listPageInfo &&
                        <h2><span>Viewing:</span><span>{listPageInfo.name}</span></h2>
                      }
                    </ListInfoHeader>
                    <TrailActions>
                      <Button onClick={(e) => { handleTrailStepPrev() }}><Icon>keyboard_arrow_left</Icon> Previous</Button>
                      <Button onClick={(e) => { handleTrailStepNext() }}>Next <Icon>keyboard_arrow_right</Icon></Button>
                    </TrailActions>
                  </ListInfo>
                }
                {
                  (!listViewEnabled && isTrailOrProject && !isTrail) &&
                  <ListInfo>
                    {
                      listPageInfo &&
                      <h2><span>Viewing:</span><span>{listPageInfo.name}</span></h2>
                    }
                    <StyledLink to="/stories">Show All Stories</StyledLink>
                  </ListInfo>
                }
                <StyledStoryList 
                  stories={filteredStories.length > 0 ? filteredStories : data} 
                  selectedStory={selectedStory} 
                  onSelectedStoryChange={setSelectedStory}
                  listViewEnabled={listViewEnabled}></StyledStoryList>
              </SecondaryContainer>
              <Dialog 
                open={aboutInfoOpen} 
                heading="About Columbia Storymap"
                toggleAboutInfo={handleAboutInfoChange} />
            </PageContent>
          </Fragment>
        }
      </Fragment>
    )
  } 
}
