import styled from "styled-components"

const MarkerButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: 0;

  &:hover {
    cursor: pointer;
  }
`
export { MarkerButton }