import React, { Fragment, useState, useEffect } from "react"
import {
  Switch,
  Route,
  useParams, 
  useHistory
} from "react-router-dom"

import { PageContent, PrimaryContainer } from "../../styles/05-components/homePageStyles"

import Header from "../Header"
import MenuPanel from "../MenuPanel"
import List from "../List"
import Dialog from "../Dialog"

export default function ListPage(props) {
  const history = useHistory()
  const [menuPanelOpen, toggleMenuPanel] = useState(false)
  const [aboutInfoOpen, toggleAboutInfo] = useState(false)

  let { list } = useParams()

  useEffect(() => {
    if(aboutInfoOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [aboutInfoOpen])
  
  function handleMenuChange() {
    toggleMenuPanel(!menuPanelOpen)
  }

  function showAllStories() {
    const allStoriesLocation = {
      pathname: "/",
      search: ""
    }

    history.replace(allStoriesLocation)
  }

  function handleAboutInfoChange() {
    toggleAboutInfo(!aboutInfoOpen)
  }

  return (
    <Fragment>
      <MenuPanel open={menuPanelOpen} allStoriesClick={showAllStories} aboutInfoChange={handleAboutInfoChange} />
      <PageContent menuOpen={menuPanelOpen}>
        <PrimaryContainer>
          <Header menuOpen={menuPanelOpen} onMenuChange={handleMenuChange} isListPage allStoriesClick={showAllStories} aboutInfoChange={handleAboutInfoChange}></Header>
          <Switch>
            <Route exact path="/trails">
              <List list={list}></List>
            </Route>
            <Route exact path="/projects">
              <List list={list}></List>
            </Route>
          </Switch>
        </PrimaryContainer>
        <Dialog 
            open={aboutInfoOpen} 
            heading="About Columbia Storymap"
            toggleAboutInfo={handleAboutInfoChange} />
      </PageContent>
    </Fragment>
  )
} 
