import React from "react"
import { Link } from 'react-router-dom';

import { 
    TagsBlock, 
    TagsHeading, 
    TagsSRHeading, 
    TagsList, 
    TagsTag
} from "../styles/05-components/tagsStyles"

export default function Tags(props) {
  return (
    <TagsBlock className={props.className}>
      {
        props.children ? <TagsHeading>{props.children}</TagsHeading>
        : <TagsSRHeading>Filter Tags</TagsSRHeading>
      }
      <TagsList>
        {
          props.tags.map((tag, index) => 
            <TagsTag key={index}>
              <Link to={`/stories?theme=${tag.slug}`} className="inverse">{tag.name}</Link>
            </TagsTag>
          ) 
        }
      </TagsList>
    </TagsBlock>
  )
} 
