import React, { useState, useEffect, Fragment } from "react"
import styled from "styled-components"
import { useAsync } from "react-async"
import {
  Link,
  useParams,
  useLocation
} from "react-router-dom"

import { getStory } from "../../api/storymapApi"

import theme from "../../styles/01-settings/theme"
import {
    StoryBlock,
    StoryHeader,
    StoryActionDate,
    StoryAction,
    StoryDate,
    StoryAuthor,
    StoryAuthorImage,
    StoryAuthorName,
    StoryAuthorHomeTown,
    StoryAddressTitle,
    StoryContent,
    StoryTitle,
    StoryText,
    StoryExtra,
    StoryFooter,
    StoryInfo,
    StoryFooterHeading,
    StoryRelatedStories,
    StoryRelatedStoriesHeading
} from "../../styles/05-components/storyStyles"
import { StoryListItem } from "../../styles/05-components/storyListStyles"

import Tags from "../Tags"
import Icon from "../Icon"
import SocialActions from "../SocialActions"
import StoryList from "../StoryList"
import Address from "../Address"
import MiniMap from "../MiniMap"
import Carousel from "../Carousel"
import Loader from "../Loader"

const StyledStoryList = styled(StoryList)`
  margin: ${theme.margin.stack};

  ${StoryListItem} {
    padding-left: 0;
    padding-right: 0;
  }
`

const StoryFlexContainer = styled.div`
  flex-grow: 1;
`

const StoryContainer = styled.div`
  transition: max-width 0.3s, margin 0.3s;

  @media (min-width: 992px) {
    max-width: 530px;
    margin: 6rem 3rem 6rem 3rem;
  }
    
  @media (min-width: 1200px) {
    margin: 6rem 6rem 6rem auto;
  }
`

const SectionHeading = styled.h1`
  font-size: ${theme.fontSizes.s};
`

const InfoSection = styled.section`
  margin: ${theme.margin.stackXL};

  &:first-of-type {
    margin-top: 0;
  }
`

const MiniMapContainer = styled.div`
  margin: ${theme.margin.stackXS};
`

export default function Story(props) {
  let { slug } = useParams()
  let location = useLocation()
  let previousLocation = `/`
  const { data, error, isPending } = useAsync({ promiseFn: getStory, slug, watch: slug })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if(isPending) {
      setIsLoading(true)
    } else {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [isPending])
  
  if (location.state && location.state.previousLocation) {
    previousLocation = location.state.previousLocation
  }

  if(!data || error) {
    // TODO: should probably add a loading state or something
    return null
  }

  const themeTags = Array.isArray(data.themes) && data.themes.length > 0 ? data.themes.map(theme => ({
    ...theme,
    url: `/?theme=${theme.slug}`
  })) : []

  function formatAddress(address) {
    let addressArray = address.split(",");
    let locationTitle, street, city, stateZip;

    if (addressArray.length > 4) {
      locationTitle = addressArray[0];
      street = addressArray[1];
      city = addressArray[2];
      stateZip = addressArray[3];
    } else {
        street = addressArray[0];
        city = addressArray[1];
        stateZip = addressArray[2];
    }

    let formattedAddress = {
      locationTitle: locationTitle,
      street: street,
      city: city,
      stateZip: stateZip
    }

    return formattedAddress
  }

  return (
    <Fragment>
      { isLoading ? <Loader />
        : <StoryBlock>
      <StoryFlexContainer>
        <StoryContainer>
          <StoryHeader>
            <StoryActionDate>
              <StoryAction>
                <Link to={previousLocation} className="no-border">
                  <Icon className="action">arrow_back</Icon>
                </Link>
              </StoryAction>
              {
                data.creation_date &&
                <StoryDate>
                  <Icon className="action">watch_later</Icon> {data.creation_date}
                </StoryDate>
              }
            </StoryActionDate>
          </StoryHeader>
          <StoryContent>
            <StoryTitle>{data.title}</StoryTitle>            
            <Carousel videos={data.videos} audio={data.audio} images={data.images}></Carousel>
            <StoryText dangerouslySetInnerHTML={{__html: data.content}}></StoryText>
            <StoryExtra dangerouslySetInnerHTML={{__html: data.collaboration_credits}}></StoryExtra>
            <StoryExtra dangerouslySetInnerHTML={{__html: data.other_info}}></StoryExtra>
          </StoryContent>
        </StoryContainer>
      </StoryFlexContainer>
      <StoryFooter>
        <StoryInfo>
          <StoryFooterHeading>Story Actions and Information</StoryFooterHeading>
          <InfoSection>
              {
                Array.isArray(data.authors) && data.authors.length > 0 ? data.authors.map(author => 
                  <Fragment key={author.id}>
                    <SectionHeading>Authors</SectionHeading>
                    <StoryAuthor>
                      {
                        author.photo &&
                        <StoryAuthorImage src={author.photo}></StoryAuthorImage>
                      }
                      <div>
                        {
                          author.name &&
                          <StoryAuthorName>{author.name}</StoryAuthorName>
                        }
                        {
                          author.hometown &&
                          <StoryAuthorHomeTown>{author.hometown}</StoryAuthorHomeTown>
                        }
                      </div>
                    </StoryAuthor>
                  </Fragment>)
                  : null 
              }
          </InfoSection>
          { data.project.name ? 
            <InfoSection>
              <SectionHeading>Projects</SectionHeading>
              <Link to={`/stories?project=${data.project.slug}`} className="inverse">{data.project.name}</Link>
            </InfoSection> : null }
          { themeTags.length > 0 ? <Tags tags={themeTags}>Tags</Tags> : null }
          {
            data.location.lat && data.location.lng &&
              <InfoSection>
                <SectionHeading>Location</SectionHeading>
                {data.location_name ? <StoryAddressTitle>{data.location_name}</StoryAddressTitle> : null}
                {data.location.address ? <Address address={formatAddress(data.location.address)}/> : null}
                <MiniMapContainer>
                  <MiniMap story={data} />
                </MiniMapContainer>
              </InfoSection>
          }
          <InfoSection>
            <SocialActions message={data.title} />
          </InfoSection>
          {
            data.related_stories ?
              <InfoSection>
                <StoryRelatedStories>
                  <StoryRelatedStoriesHeading>
                    Related Stories
                  </StoryRelatedStoriesHeading>
                    <StyledStoryList stories={data.related_stories} />
                </StoryRelatedStories>
              </InfoSection>
            : null
          }
        </StoryInfo>
      </StoryFooter>
    </StoryBlock>
      }
    </Fragment>
  )
}
