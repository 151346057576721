import styled from "styled-components"
import theme from "../01-settings/theme"
import { rgba } from "../02-tools/rgba"


const DialogOverlay = styled.div`
  align-items: center;
  background-color: ${rgba(theme.colors.black, 0.7)};
  bottom: 0;
  display: ${props => props.open ? "flex" : "none"};
  justify-content: center;
  left: 0;
  padding: 2rem;
  position: ${props => props.open ? "absolute" : "unset"};
  right: 0;
  top: 0;
  z-index: 2;
`

const DialogBlock = styled.div`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.black};
    border-radius: ${props => props.nav ? "100%" : "5px"};
    display: flex;
    flex-direction: column;
    max-width: 800px;
    padding: ${theme.spacing.l};
`

const DialogContentContainer = styled.div``

const DialogHeading = styled.h1`
    margin: 0;
`

const DialogContent = styled.p``

const Copyright = styled.p``

export { DialogOverlay, DialogBlock, DialogContentContainer, DialogHeading, DialogContent, Copyright }