import styled from "styled-components"
import theme from "../01-settings/theme"
import { NavButton } from "../05-components/buttonStyles"

const HeaderBlock = styled.header`
  align-items: center;
  background-color: ${theme.colors.white};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: ${props => props.isListPage ? "0 auto" : "0"};
  max-width: ${props => props.isListPage ? "1200px" : "none"};
  padding: ${theme.padding.inset};
  z-index: 1;

  @media (min-width: 1200px) {
    padding: ${props => props.isListPage ? `${theme.spacing.base} 0` : theme.padding.inset};
  }
`

const HeaderMenuButton = styled(NavButton)`
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`

const HeaderLogoContainer = styled.div`
  display: none;
  margin: ${theme.margin.inline};

  @media (min-width: 768px) {
    display: block;
    margin: ${theme.margin.inlineL};
    text-align: left;
  }
`

const HeaderMenu = styled.ul`
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: 768px) {
    display: block;
  }
`

const HeaderMenuItem = styled.li`
  display: inline-block;
  margin: ${theme.margin.inline};

  &:last-of-type {
    margin: 0;
  }
`

export { HeaderBlock, HeaderMenuButton, HeaderLogoContainer, HeaderMenu, HeaderMenuItem }