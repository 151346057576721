import React from "react"

import styled from "styled-components"
import theme from "../styles/01-settings/theme"
import { MenuBlock, MenuLogoContainer } from "../styles/05-components/menuStyles"

import Logo from "./Logo"
import MainNav from "./MainNav"

const StyledMainNav = styled(MainNav)`
  flex-grow: 1;
  margin: ${theme.margin.stackXL};
`

export default function Menu(props) {
  function handleAboutClick(status) {
    props.aboutInfoChange(!status)
  }
  
  function handleAllStoriesClick() {
    props.allStoriesClick()
  }

  return (
    <MenuBlock {...props}>
      <MenuLogoContainer>
        <Logo 
          allStoriesClick={handleAllStoriesClick}
          aboutClick={handleAboutClick}></Logo>
      </MenuLogoContainer>
      <StyledMainNav 
        allStoriesClick={handleAllStoriesClick}
        aboutClick={handleAboutClick}/>
    </MenuBlock>
  )
}
