import React from "react"

import styled from "styled-components"
import theme from "../styles/01-settings/theme"
import { StoryListContainer, StoryListBlock, StoryListItem } from "../styles/05-components/storyListStyles"
import StorySummary from "./StorySummary"
import { StorySummaryContentContainer } from "../styles/05-components/storySummaryStyles"

const StyledStorySummary = styled(StorySummary)`
  border-radius: 0;
  color: ${theme.colors.black};
  padding: 0;
  padding-bottom: 0;
  position: unset;
  background: transparent;
  width: 100%;

  @media (min-width: 768px) {
    display: block;
  }
  
  ${StorySummaryContentContainer} {
    box-shadow: none;

    @media (min-width: 768px) {
      background-color: transparent;
    }
  }
`

export default function StoryList(props) {
  const selectedStory = props.selectedStory;

  return (
    <StoryListContainer className={props.className}>
      <StoryListBlock>
        {
          Array.isArray(props.stories) && props.stories.length > 0 ?
            props.stories.map((story, i) => {
                return (
                  <StoryListItem key={i} selected={story === selectedStory} id={story.id}>
                    <StyledStorySummary story={story} listViewEnabled={props.listViewEnabled}></StyledStorySummary>
                  </StoryListItem>
                )
              }
            )
          : null
        }
      </StoryListBlock>
    </StoryListContainer>
  )
}
