import colors from "./colors/colors"
import spacing from "./spacing/spacing"
import margin from "./spacing/margin"
import padding from "./spacing/padding"
import fontFamily from "./font/family"
import fontSizes from "./font/sizes"
import fontWeights from "./font/weights"
import breakpoints from "./breakpoints/breakpoints"

export default { 
    colors, 
    spacing, 
    margin, 
    padding, 
    fontFamily,
    fontSizes,
    fontWeights,
    breakpoints
}