import React from "react"
import { TwitterShareButton, TwitterIcon, FacebookShareButton, FacebookIcon } from "react-share"

import { SocialActionsBlock, SocialActionsHeading, SocialActionsList, SocialActionsItem } from "../styles/05-components/socialActionsStyles"

export default function SocialActions(props) {
  return (
    <SocialActionsBlock>
      <SocialActionsHeading>Share</SocialActionsHeading>
      <SocialActionsList>
        <SocialActionsItem>
          <TwitterShareButton url={window.location.href} title={props.message}><TwitterIcon size={32} round={true}/></TwitterShareButton>
        </SocialActionsItem>
        <SocialActionsItem>
          <FacebookShareButton url={window.location.href} quote={props.message}><FacebookIcon size={32} round={true}/></FacebookShareButton>
        </SocialActionsItem>
      </SocialActionsList>
    </SocialActionsBlock>
  )
}
