import styled from "styled-components"
import theme from "../01-settings/theme"
import { rgba } from "../02-tools/rgba"

const DefaultButton = styled.button`
    appearance: none;
    background-color: transparent;
    border: 1px solid;
    border-color: ${props => props.primary ? props.theme.accentColor : theme.colors.white};
    border-radius: ${props => props.nav ? "100%" : "5px"};
    display: inline-block;
    line-height: ${props => props.icon ? "0" : "initial"};
    padding: ${theme.padding.insetS};
    text-align: center;
    text-decoration: none;
    width: ${props => props.icon ? "auto" : "100%"};
`

const ActionButton = styled(DefaultButton)`
    background-color: ${props => props.primary ? props.theme.accentColor : "transparent"};
    color: ${theme.colors.white};
    padding: ${theme.padding.insetXS};

    &:hover {
        background-color: ${props => props.primary ? props.theme.accentColorHover : rgba(theme.colors.white, 0.2)};
        border-color: ${props => props.primary ? props.theme.accentColorHover : theme.colors.white};
    }
    
    &:active {
        background-color: ${props => props.primary ? props.theme.accentColorActive : "transparent"};
    }
`

const NavButton = styled.button`
    appearance: none;
    background-color: none;
    border: none;
    padding: 1rem;
`

export { DefaultButton, ActionButton, NavButton }