import styled from "styled-components"
import theme from "../01-settings/theme"
import { NavButton } from "../05-components/buttonStyles"

const UtilityNavBlock = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    @media (min-width: 768px) {
        display: none;
    }
`

const UtilityNavItem = styled.li`
    display: inline-block;
    margin: ${theme.margin.inlineS};

    &:last-of-type {
        margin: 0;
    }
`

const UtilityNavButton = styled(NavButton)``

const UtilityNavMenu = styled.div`
    background-color: ${theme.colors.darkGrey};
    display: ${props => props.open ? "block" : "none"};
    padding: ${theme.padding.insetL};
    position: absolute;
    right: 0;
    top: 79px;
    z-index: 1;
`

const UtilityNavMenuList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`

const UtilityNavMenuItem = styled.li`
    margin: ${theme.margin.stack};

    &:first-of-type {
        margin: 0;
    }
`

const UtilityNavMenuButton = styled.button`
    appearance: none;
    border: none;
    background-color: transparent;
    color: ${theme.colors.white};
    text-decoration: underline;
`

const UtilityNavFilters = styled.div`
  background-color: ${theme.colors.darkGrey};
  display: ${props => props.open ? "block" : "none"};
  left: 0;
  padding: ${theme.padding.insetS};
  position: absolute;
  right: 0;
  top: 78px;
  z-index: 1;

  @media (min-width: 768px) {
    display: none;
  }
`

export { UtilityNavBlock, UtilityNavItem, UtilityNavButton, UtilityNavMenu, UtilityNavMenuList, UtilityNavMenuItem, UtilityNavMenuButton, UtilityNavFilters }