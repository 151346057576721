import styled from "styled-components"
import theme from "../01-settings/theme"

const ActionSet = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: ${theme.margin.stackL};
    padding: 0;
    width: 100%;
`

const ActionSetAction = styled.li`
    display: inline-block;
    flex-basis: 100%;
    flex-grow: 1;
    margin: ${theme.margin.stackS};
    width: 100%;

    &:first-of-type {
        margin: 0;
    }

    @media (min-width: 768px) {
        flex-basis: auto;
        margin: ${theme.margin.inlineS};
        width: auto;

        &:first-of-type {
            margin: ${theme.margin.inlineS};
        }

        &:last-of-type {
            margin: 0;
        }
    }

`

export { ActionSet, ActionSetAction }